import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import './style.scss'
import { Link } from 'gatsby'

class WorkPreview extends React.Component {
  renderDescription() {
    return (
      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
        <div style={{ margin: 'auto' }}>
          <p> {this.props.description} </p>
        </div>
        <Link to={this.props.page}>
          <Button style={{ margin: '2%', backgroundColor: '#0099cc' }}>
            Learn More
          </Button>
        </Link>
      </Col>
    )
  }

  renderMedia() {
    return (
      <Col xs={12} sm={12} md={6} lg={6} xl={6}>
        <Row style={{ padding: '5%' }}>
          <GatsbyImage image={this.props.image} alt="" style={{ maxHeight: '400px' }} />
        </Row>
      </Col>
    );
  }

  render() {
    let previewClass = ''
    this.props.isRight ? (previewClass = 'mediaRight') : ''
    return (
      <>
        <Row
          className={previewClass}
          style={{ border: 'solid #0099cc', backgroundColor: '#22333B' }}
        >
          <h3 style={{ marginTop: '5%' }}>{this.props.title}</h3>
          {this.props.isRight ? this.renderDescription() : this.renderMedia()}
          {this.props.isRight ? this.renderMedia() : this.renderDescription()}
        </Row>
        <hr
          className="my-2"
          style={{ border: 'solid white 1px', width: '50%' }}
        />
      </>
    )
  }
}

export default WorkPreview
