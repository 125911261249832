import { getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import './style.scss'
import { Link } from 'gatsby'
import WorkPreview from '../../components/pastWorks/workPreview'

class PastWorks extends React.Component {
  render() {
    const { location, data } = this.props
    const honorsCollege1 = getImage(data.honorsCollege1.childImageSharp)
    const gallerySample = getImage(data.gallerySample.childImageSharp)
    const mwr = getImage(data.mwr.childImageSharp)

    return (
      <Layout isHome={false}>
        <section
          data-cy="past-works-dir"
          className="content-section text-center"
          style={{
            paddingTop: '5%',
            backgroundColor: 'rgb(255, 255, 255, 0.15)',
          }}
        >
          <Container>
            <Row>
              <h2> Past Works </h2>
              <p>
                Thanks for checking out some of my past works! This page
                features client and personal projects that I've played a
                substantial role in developing. Other recent projects such as my
                work for Microsoft and Ulta Beauty, are not yet public. Feel
                free to inquire about these or other skills at
                <a href="mailto:nickjtorres5@gmail.com">
                  {' '}
                  nickjtorres5@gmail.com,{' '}
                </a>{' '}
                or view my
                <Link to="/resume/" className="nav-link">
                  {' '}
                  Resume
                </Link>{' '}
                for more information.
              </p>
            </Row>
            <WorkPreview
              image={gallerySample}
              description="While learning more about the new and popular Gatsby.js React framework, I decided to make
                  a photo gallery to display previous camping trips to the Boundary Waters Canoe Area, or simply BWCA.
                  I had fun implementing and optimizing this project, and it is now live on my website! Follow the
                  below link for more information and project specific tools and technologies used."
              isRight={false}
              page="/galleryWork"
              title="Photo Gallery Version 2"
            />
            <WorkPreview
              image={mwr}
              description={`Midwest Radio is an iOS and Android application developed to promote local radio stations based
                in the midwestern United States and Canada. This app was inspired to aid in the curriculum for Ball State 
                University\'s School of Music, and was released to the iOS and Android stores. Since the completion,
                this application has been successfully used in the classroom, and has launched West Coast and 
                Southern Radio Player counterparts on the app store.`}
              isRight={true}
              page="/mwrWorks"
              title="Midwest Radio"
            />

            <WorkPreview
              image={honorsCollege1}
              description="As a freshman college student, graduating on time can be quite a challenge.
                  To combat this, Ball State University implemented a new internal website to help students plan
                 and track their class credits. Users of the site increased their knowlege by watching 
                  videos, completing interactive questions, and submitting forms in the browser application. This project 
                  served as both an educational aid and informative tool, which tracked individual learning progress."
              isRight={false}
              page="/honorsCollegeWork"
              title="Ball State Honors College Website"
            />
          </Container>
        </section>
      </Layout>
    )
  }
}

export default PastWorks

export const query = graphql`
  query PortfolioQuery {
    honorsCollege1: file(name: { eq: "honorsCollege1" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
        )
      }
    }
    mwr: file(name: { eq: "mwr" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
        )
      }
    }
    gallerySample: file(name: { eq: "gallerySample" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
        )
      }
    }
  }
`
